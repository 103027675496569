import React from 'react';

export default function TOS() {
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
    };

    const iframeStyle = {
        border: 'none',
        width: '80%', // Adjust as needed
        height: '80%', // Adjust as needed
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add a subtle shadow
        borderRadius: '8px', // Optional: Rounded corners
    };

    return (
        <div style={containerStyle}>
            <iframe
                src={`${process.env.PUBLIC_URL}/TERMSOFSERVICE.html`}
                style={iframeStyle}
                title="Terms of Service"
            />
        </div>
    );
}
