// TODO: replace routing with actual routes

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import "./App.scss";
import LandingPage from "./pages/Home/LandingPage";
import TOS from "./pages/Legal/TOS";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import EULA from "./pages/Legal/EULA";

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<LandingPage />} />

          <Route path="/tos" element={<TOS />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/eula" element={<EULA />} />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  );
}
export default App;
